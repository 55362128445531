const getURL = (string) => {
  string = string.replace(/ /g, '-');
  string = string.replace(/[&'()./]/g, '-')
  string = string.replace(/-{2}/g, '-')                             // Allow just single hyphens
  string = string.toLowerCase();
  string = string.replace(/ä/g, 'ae');                              // Replace umlauts
  string = string.replace(/ö/g, 'oe');
  string = string.replace(/ü/g, 'ue');
  string = string.replace(/ß/g, 'ss');
  string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')  // á => a, ü => u
  return string;
};

export default getURL;
