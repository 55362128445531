import getURL from './../../helper/getURL.js';


const getDetailsURL = (homeTeamId, awayTeamId, strings) => {

  const firstTeamId = Math.min(homeTeamId, awayTeamId);
  const secondTeamId = Math.max(homeTeamId, awayTeamId);

  let url = '';
  url += strings.small.teams[firstTeamId] || strings.medium.teams[firstTeamId];
  url += '-gegen-';
  url += strings.small.teams[secondTeamId] || strings.medium.teams[secondTeamId];
  url += '-';
  url += firstTeamId;
  url += '-';
  url += secondTeamId;

  return getURL(url);
}

export default getDetailsURL;
