/**
 * At first each match had it's own URL e. g. /1-fc-koeln-gegen-borussia-dortmund-8984759
 * The last number is the matchId. But the problem is that these URLs change often (on each new
 * match against each other). This did not work and search engines do not like new and often changing
 * URLs. The result were almost zero clicks. Now Details groups all matches between two teams into a single
 * non changing URL. The last ids are the teamIds. Now it may work better for SEO. The future will show.
 * 
 * TODO:
 * - If there are multiple matches between two teams (see DetailsMatches), allow to change the match
 * - On the right side of each ended match in DetailsMatches there is a win/draw/loss icon, remove it.
 * - Check the meta section
 */

import React from 'react';
import DetailsHeader from './DetailsHeader.js';
import DetailsEvents from './DetailsEvents.js';
import DetailsComparison from './DetailsComparison.js';
import DetailsBroadcasts from './DetailsBroadcasts.js';
import DetailsMatches from './DetailsMatches.js';
import Lazy from './../Lazy.js';
import withErrorBoundary from './../common/withErrorBoundary.js';
import getRoundName from './../../helper/getRoundName.js';
import isTomorrow from './../../helper/isTomorrow.js';
import isToday from './../../helper/isToday.js';
import isYesterday from './../../helper/isYesterday.js';
import isMatchEnded from './../../helper/isMatchEnded.js';
import isMatchActive from './../../helper/isMatchActive.js';
import tableStyles from './../../styles/tableStyles.js';

const Details = ({matches, strings, firstTeamId, secondTeamId}) => {
  console.log('Details.render()');

  const foundMatches = matches
  .filter((match) => 
    (match.homeTeamId === firstTeamId && match.awayTeamId === secondTeamId) ||
    (match.homeTeamId === secondTeamId && match.awayTeamId === firstTeamId)
  )
  .sort((a, b) => {
    const startAt1 = new Date(a.startAt);
    const startAt2 = new Date(b.startAt);
    if (startAt1 > startAt2) return 1;
    else if (startAt1 < startAt2) return -1;
    else return 0;
  });
  const pastMatches = foundMatches.filter((match) => isMatchEnded(match.status));
  const nextMatches = foundMatches.filter((match) => !isMatchEnded(match.status));


  /**
   * Order which match to show:
   * 1. Take active match
   * 2. Take ended match not older than 2 day
   * 3. Take next scheduled match
   * 4. Take last ended match
   */

  let currentMatch;
  currentMatch = nextMatches.find(m => isMatchActive(m.status));          // 1. Take active match
  if (
    !currentMatch &&
    pastMatches.length > 0 &&
    pastMatches?.[pastMatches.length-1] &&
    (
      Date.now() - 2*24*60*60*1000
      <
      (new Date(pastMatches[pastMatches.length-1].startAt)).getTime()
    )
  ) currentMatch = pastMatches[pastMatches.length-1];

  if (!currentMatch && nextMatches[0]) currentMatch = nextMatches[0];     // 3. Take next scheduled match

  if (!currentMatch && pastMatches[pastMatches.length-1])                 // 4. Take last ended match
    currentMatch = pastMatches[pastMatches.length-1];

  if (!currentMatch) return null;

  let match = currentMatch;

  /* Meta */
  const homeTeam = strings.medium.teams[match.homeTeamId];
  const awayTeam = strings.medium.teams[match.awayTeamId];
  const homeTeamSmall = strings.small.teams[match.homeTeamId];
  const awayTeamSmall = strings.small.teams[match.awayTeamId];
  const homeGoals = match.homeGoals;
  const awayGoals = match.awayGoals
  const startAt = new Date(match.startAt);
  let resultText;
  if (homeGoals === awayGoals) resultText = `Das Spiel ging ${homeGoals}:${awayGoals} unentschieden aus`;
  else if (homeGoals > awayGoals) resultText = `${homeTeamSmall} gewann das Spiel gegen ${awayTeamSmall} mit ${homeGoals}:${awayGoals}`;
  else if (homeGoals < awayGoals) resultText = `${awayTeamSmall} gewann das Spiel gegen ${homeTeamSmall} mit ${homeGoals}:${awayGoals}`;

  if (isTomorrow(startAt)) {
    document.title = `MORGEN: ${homeTeamSmall} -:- ${awayTeamSmall} | Übertragung | TV | Live Ergebnisse`;
    document.head.children.description.content = `Morgen spielt ${homeTeam} gegen ${awayTeam} um ${startAt.toLocaleTimeString('de-DE').slice(0, -3)} (${strings.medium['tournament'+match.tournamentId.toString()]}). Das Spiel wird auf ... übertragen.`;
      }
  else if (isMatchActive(match.status)) {                           // Today + Live
    document.title = `JETZT: ${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Live Ergebnisse | Übertragung`;
    document.head.children.description.content = `Es spiel aktuell ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). Alle Tore, Torschützen und live Ergebnisse in der App. Das Spiel wird auf ... übertragen.`;
  }
  else if (isToday(startAt)) {                                      // Today + before
    document.title = `HEUTE: ${homeTeamSmall} -:- ${awayTeamSmall} | Übertragung | TV | Live Ergebnisse`;
    document.head.children.description.content = `Heute spielt ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). Alle Tore, Torschützen und live Ergebnisse in der App. Das Spiel wird auf ... übertragen.`;
  }
  else if (isToday(startAt) && isMatchEnded(match.status)) {        // Today + after
    document.title = `HEUTE: ${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Ergebnisse | Tore`;
    document.head.children.description.content = `Heute spielte ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). ${resultText}. Hier findest du alle Tore, Torschützen und Ergebnisse.`;
  }
  else if (match.status === 'scheduled') {                          // Starts in over 1 day
    document.title = `${homeTeamSmall} -:- ${awayTeamSmall} | Übertragung | TV | Live Ergebnisse`;
    document.head.children.description.content = `Am ${startAt.toLocaleString('de-DE', {weekday: 'long'})} den ${startAt.toLocaleDateString('de-DE')} um ${startAt.toLocaleTimeString('de-DE').slice(0, -3)} spielt ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). Das Spiel wird auf ... übertragen.`;
  }
  else if (isYesterday(startAt)) {                                  // Ended yesterday
    document.title = `GESTERN: ${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Ergebnisse | Tore`;
    document.head.children.description.content = `Gestern spielte ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). ${resultText}. Hier findest du alle Tore, Torschützen und Ergebnisse.`;
  }
  else if (isMatchEnded(match.status)) {                            // Ended over 1 day ago
    document.title = `${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Ergebnisse | Tore`;
    document.head.children.description.content = `Am ${startAt.toLocaleString('de-DE', {weekday: 'long'})} den ${startAt.toLocaleDateString('de-DE')} spielte ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). ${resultText}. Hier findest du alle Tore, Torschützen und Ergebnisse.`;
  }

  return (
    <div id="details" style={tableStyles.container} className="safe-area-desktop-small">
      <Lazy>
        <DetailsHeader match={match} strings={strings}/>
        <DetailsEvents match={match} strings={strings}/>
        <DetailsComparison match={match} matches={matches} strings={strings}/>
        <DetailsBroadcasts match={match} strings={strings}/>
        <DetailsMatches matches={foundMatches} strings={strings}/>
        <div style={{...tableStyles.footerView, ...styles.footerView}}><div style={tableStyles.footerText} /*maxFontSizeMultiplier={1.3}*/>{strings.medium['tournament' + match.tournamentId]}{', '}{strings.medium[match.stageStringId] || getRoundName(match.roundId, match.rounds, strings)}</div></div>
      </Lazy>
    </div>
  );
};

const styles = {
  footerView: {
    justifyContent: 'center',
  },
};

export default withErrorBoundary(Details, 'Details');
