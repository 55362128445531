/**
 * Performance hints
 * - With 2k iterations, new Date(), map, for, push ist very fast (<1ms)
 * - push(<div/>) is very slow (120ms)
 * - filter+map is 20% slower than for (19ms/15ms) but still very fast on 20k iterations
 * 
 * /uebermorgen, /vorgestern, /wochenende have a weak search volumne
 */

import React from 'react';
import {useLocation, Link} from 'react-router-dom';
import Lazy from './../Lazy.js';
import Icon from './../Icon.js';
import MatchesByTournament from './../match/MatchesByTournament.js';
import withErrorBoundary from './../common/withErrorBoundary.js';
import isToday from './../../helper/isToday.js';
import isYesterday from './../../helper/isYesterday.js';
import isTomorrow from './../../helper/isTomorrow.js';
import isDay from './../../helper/isDay.js';
import getWeekdayStringId from './../../helper/getWeekdayStringId.js';
import tableStyles from './../../styles/tableStyles.js';
import colors from './../../colors.js';
import NextMatchesCountdown from '../common/NextMatchesCountdown.js';

const Calendar = ({matches, strings, date}) => {
  console.log('Calendar.render()');

  const location = useLocation();

  date = date || new Date();                                      // If no date is given (usually if it is /today, /yesterday create new Date()
  if (location.pathname === '/heute') {}
  else if (location.pathname === '/morgen') date.setDate(date.getDate() + 1);
  else if (location.pathname === '/gestern') date.setDate(date.getDate() - 1);
  else if (location.pathname === '/samstag') date.setDate(date.getDate() + (6 - date.getDay()) % 7);
  else if (location.pathname === '/sonntag') date.setDate(date.getDate() + (7 - date.getDay()) % 7);

  const nextDate = new Date(date);
  nextDate.setDate(date.getDate() - 1);
  let previousDay = `/${nextDate.getDate()}-${nextDate.getMonth()+1}-${nextDate.getFullYear()}`;

  const previousDate = new Date(date);
  previousDate.setDate(date.getDate() + 1);
  let nextDay = `/${previousDate.getDate()}-${previousDate.getMonth()+1}-${previousDate.getFullYear()}`;

  let foundMatches = matches.filter((match) => {                    // 1. Find all matches today (or yesterday, ...)
    const startAt = new Date(match.startAt);
    if (isToday(startAt, date)) {
      return true;
    }
    return false;
  });

  /* Head Title */
  if (location.pathname === '/heute') document.title = `Fußball HEUTE Live | ${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()} | Spiele, Ergebnisse & TV`;
  else if (location.pathname === '/morgen') document.title = 'Fußball Morgen: Alle Spiele, alle TV Übertragungen';
  else if (location.pathname === '/gestern') document.title = 'Fußball Gestern: Alle Ergebnisse, alle Tore';
  else if (location.pathname === '/samstag') document.title = 'Fußball am Samstag: Alle Spiele, alle TV Übertragungen';
  else if (location.pathname === '/sonntag') document.title = 'Fußball am Sonntag: Alle Spiele, alle TV Übertragungen';
  else document.title = `Fußball am ${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}: Alle Spiele und Ergebnisse`;

  /* Head Meta Description */
  if (location.pathname === '/heute') document.head.children.description.content = `Fussball heute live ⚽️ am ${strings.medium[getWeekdayStringId(date)]}, den ${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()} ⚽️ Alle heutigen Spiele, Ligen, TV Übertragungen und Fussball Ergebnisse`;
  else if (location.pathname === '/morgen') document.head.children.description.content = 'Wer spielt morgen und wo wird das Spiel im TV übertragen? Alle Spiele, alle Ligen, alle TV Sender.';
  else if (location.pathname === '/gestern') document.head.children.description.content = 'Alle Fußball Ergebnisse, Tabellen und Spiele von gestern. Inkl. aller Ligen wie der Bundesliga, Champions League, DFB Pokal, EM und Co.';
  else if (location.pathname === '/samstag') document.head.children.description.content = 'Wer spielt am Samstag? Alle Spiele, alle TV Übertragungen, alle Ligen.';
  else if (location.pathname === '/sonntag') document.head.children.description.content = 'Wer spielt am Sonntag? Alle Spiele, alle TV Übertragungen, alle Ligen.';
  else document.head.children.description.content = `Alle Fußball Spiele am ${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}. 1. Bundesliga, WM und Co.`;

  return (
    <div id="calendar" style={tableStyles.container} className="safe-area-desktop-small">
      <Lazy>
        <div style={styles.titleContainer}>
          <Link to={isToday(date) ? '/gestern' : isTomorrow(date) ? '/heute' : isDay(date, 2) ? '/morgen' : previousDay} style={styles.titleLeftTouch}>
            <Icon androidName="chevron-left" iosName="chevron-back-outline" size={33} color={colors.primary}/>
            <div style={styles.titleButtonText}>
              {
                isToday(date)
                ? strings.medium.yesterday
                : isTomorrow(date)
                ? strings.medium.today
                : ''
              }
            </div>
          </Link>
          <h1 style={styles.title}>
            {
              isToday(date) ? strings.medium.today
              : isYesterday(date) ? strings.medium.yesterday
              : isTomorrow(date) ? strings.medium.tomorrow
              : `${strings.medium[getWeekdayStringId(date)].substring(0, 2)}. ${date.getDate()}.${date.getMonth()+1}.`
            }
          </h1>
          <Link to={isToday(date) ? '/morgen' : isYesterday(date) ? '/heute' : isDay(date, -2) ? '/gestern' : nextDay} style={styles.titleRightTouch}>
            <div style={styles.titleButtonText}>
              {
                isToday(date)
                ? strings.medium.tomorrow
                : isYesterday(date)
                ? strings.medium.today
                : ''
              }
            </div>
            <Icon androidName="chevron-right" iosName="chevron-forward-outline" size={33} color={colors.primary}/>
          </Link>
        </div>

        <MatchesByTournament matches={foundMatches} strings={strings}/>

        { /* No matches found */                                    // "Keine Spiele"
          foundMatches.length === 0 && (
            <>
              <div style={tableStyles.headerViewEmpty}/>
              <div style={{...tableStyles.rowViewFirstLast, ...styles.noMatches}}>
                <div style={tableStyles.rowText}>
                  {strings.small.noFootballMatches}
                </div>
              </div>
            </>
          )
        }

        {                                                         // "Nächstes Spiel" but only on /heute page
          foundMatches.length === 0 && location.pathname === '/heute' && (
            <>
              <div style={tableStyles.footerViewEmpty}/>
              <NextMatchesCountdown matches={matches}/>
            </>
          )
        }

        <div style={tableStyles.footerViewEmpty}/>

        <div style={styles.promo}>
          <div style={tableStyles.rowText}>Lade dir jetzt kostenlos unsere <b>Fußball Ergebnisse App</b> im App Store runter.<br/>⬇️ Schnell, einfach und übersichtlich! ⬇️</div>
        </div>
        <div style={tableStyles.footerViewEmpty}/>

      </Lazy>
    </div>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 17,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 20,
    marginBottom: 16,
    textAlign: 'center',
  },
  titleLeftTouch: {
    display: 'flex',
    flexBasis: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleRightTouch: {
    display: 'flex',
    flexBasis: 1,
    flexGrow: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleButtonText: {
    fontSize: 13,                                                   // iOS 15 | table round footer
    color: colors.primary,
  },
  noMatches: {
    justifyContent: 'center',
  },
  promo: {
    ...tableStyles.rowViewFirstLast,
    justifyContent: 'center',
    textAlign: 'center',
    height: 'auto',
    paddingTop: 8,
    paddingBottom: 8,
  }
};

export default withErrorBoundary(Calendar, 'Calendar');
