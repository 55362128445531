import React from 'react';
import Match from './../match/Match.js';
import tableStyles from './../../styles/tableStyles.js';

const DetailsMatches = ({matches, strings}) => {
  console.log('DetailsMatches.render()');
  if (matches.length === 0) return null;
  matches = matches.sort((a, b) => new Date(a.startAt) - new Date(b.startAt)); // Order matches by startAt
  return (
    <>
      <div style={tableStyles.headerView}>
        <div style={tableStyles.headerText}>{strings.medium.matches}</div>
      </div>
      {
        matches.map((match, index, nextMatches) => {
          return (
            <React.Fragment key={match.matchId}>
              <Match match={match} strings={strings} detailsTeamId={match.homeTeamId} first={index === 0} last={index === nextMatches.length-1}/>
              {index !== nextMatches.length-1 && <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>}
            </React.Fragment>
          );
        })
      }
    </>
  );
};

export default DetailsMatches;
