import React from 'react';
import {Link} from 'react-router-dom';
import useWindowDimensions from './../../helper/useWindowDimensions.js';
import Icon from './../Icon.js';
import getURL from './../../helper/getURL.js';
import styles from './standingStyles.js';
import colors from './../../colors.js';

const Standing = ({standing, strings, last, navigation}) => {
  console.log('Standing.render()');
  const {width} = useWindowDimensions();
  const {resultStringId, rank, teamId, teamStringId, played, won, drawn, lost, goalsFor, goalsAgainst, goalDifference, points} = standing;
  return (
    <Link to={'/' + getURL(strings.small.teams[teamId] || strings.medium.teams[teamId]) + '/' + teamId} style={last ? {...styles.container, ...styles.last} : styles.container}>
      {
        width >= 360 && (
          strings.icon[resultStringId]
          ? <Icon style={styles.resultIcon} androidName={strings.icon[resultStringId]} size={24} color={colors.textSecondary}/>
          : <div style={styles.resultText} /*numberOfLines={1}*/>{strings.shortcut[resultStringId]}</div>
        )
      }
      <div style={styles.rank} /*numberOfLines={1}*/>{rank}.</div>
      <div style={styles.team} className="ellipsis">
        {strings.small.teams[teamId] || strings.medium.teams[teamId] || strings.small[teamStringId] || strings.medium[teamStringId] || teamStringId || teamId}
      </div>
      <div style={styles.played} /*numberOfLines={1}*/>{played}</div>
      <div style={styles.won} /*allowFontScaling={false}*/>{won}</div>
      <div style={styles.drawn} /*allowFontScaling={false}*/>{drawn}</div>
      <div style={styles.lost} /*allowFontScaling={false}*/>{lost}</div>
      <div style={styles.goalsFor} /*allowFontScaling={false}*/>{goalsFor}</div>
      <div style={styles.goalsSeparator} /*allowFontScaling={false}*/>:</div>
      <div style={styles.goalsAgainst} /*allowFontScaling={false}*/>{goalsAgainst}</div>
      <div style={styles.goalDifference} /*numberOfLines={1}*/>{goalDifference}</div>
      <div style={styles.points} /*numberOfLines={1}*/>{points}</div>
    </Link>
  );
};

/**
 * Performance optimization
 * - Render Match only if something changed
 * - useMemo did not work
 */
 class StandingSCU extends React.Component{
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.standing.hash !== this.props.standing.hash ||
      nextProps.strings       !== this.props.strings ||
      nextProps.last          !== this.props.last ||
      nextProps.highlighter   !== this.props.highlighter ||
      nextProps.renamer       !== this.props.renamer ||
      nextProps.isDetails     !== this.props.isDetails
    );
  }
  render() {
    console.log('StandingSCU.render()');
    return <Standing {...this.props}/>;
  }
}

export default StandingSCU;
