import React from 'react';
import {Link} from 'react-router-dom';
import Icon from './../Icon.js';
import getDate from './getDate.js';
import isMatchActive from './../../helper/isMatchActive.js';
import getURL from '../../helper/getURL.js';
import tableStyles from './../../styles/tableStyles.js';

const DetailsHeader = ({match, strings, navigation}) => {
  console.log('DetailsHeader.render()');
  const isHighScore = (match.homeGoals > 9) || (match.awayGoals > 9);
  return (
    <>
      <div style={tableStyles.headerViewEmpty}/>
      <div style={{...tableStyles.rowViewFirstLast, ...styles.container}}>
        {isMatchActive(match.status) && <div style={styles.liveText}>LIVE{match.minute && ' ' + match.minute + '\''}</div>}
        <div style={styles.teamAndGoalsContainer}>
          <Link style={styles.teamPressable} to={'/' + getURL(strings.small.teams[match.homeTeamId] || strings.medium.teams[match.homeTeamId]) + '/' + match.homeTeamId}>
            <div style={styles.teamView}><div style={styles.teamText} /*numberOfLines={1}*/>{strings.small.teams[match.homeTeamId] || strings.medium.teams[match.homeTeamId]}</div></div>
            {/* <Icon style={styles.infoIcon} androidName="information-outline" iosName="information-circle-outline" androidSize={14} iosSize={15} color={colors.primary}/> */}
          </Link>
          <div style={isHighScore ? {...styles.goals, ...{fontSize: 35}} : styles.goals}>{match.status === 'scheduled' ? '-' : match.homeGoals}</div>
          <div style={isHighScore ? {...styles.colon, ...{fontSize: 35}} : styles.colon}>:</div>
          <div style={isHighScore ? {...styles.goals, ...{fontSize: 35}} : styles.goals}>{match.status === 'scheduled' ? '-' : match.awayGoals}</div>
          <Link style={styles.teamPressable} to={'/' + getURL(strings.small.teams[match.awayTeamId] || strings.medium.teams[match.awayTeamId]) + '/' + match.awayTeamId}>
            <div style={styles.teamView}><div style={styles.teamText} /*numberOfLines={1}*/>{strings.small.teams[match.awayTeamId] || strings.medium.teams[match.awayTeamId]}</div></div>
            {/* <Icon style={styles.infoIcon} androidName="information-outline" iosName="information-circle-outline" androidSize={14} iosSize={15} color={colors.primary}/> */}
          </Link>
        </div>
        <div style={styles.statusText}>
          {
            match.status === 'scheduled' ? strings.medium.notYetStarted
            : match.status === 'ended' ? strings.medium.end
            : strings.medium[match.status]
          }
        </div>
      </div>
      <div style={{...tableStyles.footerView, ...styles.footerView}}>
        <div style={tableStyles.footerText}>
          <Icon name="whistle-outline" color="rgba(60, 60, 67, 0.6)" size={13}/>
          {' '}{getDate(new Date(match.startAt), strings)}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'auto',
    paddingTop: 16,
    paddingBottom: 16,
  },
  liveText: {
    alignSelf: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
    fontWeight: 'bold',
    backgroundColor: 'red',
    color: 'white',
  },
  teamAndGoalsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  teamPressable: {
    display: 'flex',
    flexBasis: 1,
    flexGrow: 1,
    paddingTop: 17,
  },
  teamView: {                                                       // Without this extra <div> the <div> does not get ellipsed correctly
    flexBasis: 1,
    flexGrow: 1,
    flexDirection: 'row',
  },
  teamText: {
    fontSize: 17,
    flexBasis: 20,
    flexShrink: 0,
    flexGrow: 1,
    textAlign: 'center',
  },
  infoIcon: {
    alignSelf: 'center',
  },
  goals: {
    flexBasis: 55,
    flexGrow: 0,
    flexShrink: 0,
    fontSize: 55,
    textAlign: 'center',
  },
  colon: {
    fontSize: 50,
    paddingBottom: 6,
  },
  statusText: {
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  footerView: {
    justifyContent: 'flex-end',
  },
};

export default DetailsHeader;
